import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { IdRequest } from '../interfaces/idRequest'
import { Observable, map, catchError, of } from 'rxjs';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class IdGeneratorService {

  private endpointURL = '/api/getnewid';

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }
  public getNewIdPromise(): Promise<string> {
    return this.http.get<IdRequest>(`${this.endpointURL}`, {
      params: new HttpParams()
        .set('amountOfIds', <number>1)
        .set('networkRange', <string>'')
    }
    ).pipe(
      map((data) => {
        try {
          return JSON.parse(data.ids)[0]
        } catch (error) {
          throw new Error(`${this.endpointURL} response caused error: ` + error)
        }
      }),
      catchError((err) => {
        this.snackBar.open('Error: Did not receive new id for sample set', null, { duration: 2000 })
        console.error(err)
        return []
      })
    ).toPromise()
  }

  public getNewId(amountofIds: number = 10, networkRange: string = ''): Observable<string[]> { // TODO mind better networkRange defaults?
    return this.http.get<IdRequest>(`${this.endpointURL}`, {
      params: new HttpParams()
        .set('amountOfIds', <number>amountofIds)
        .set('networkRange', <string>networkRange)
    }
    ).pipe(
      map(data => {
        try {
          if (JSON.parse(data.ids).length == 0) {
            throw new Error(`${this.endpointURL} did not return ids`)
          }

          return JSON.parse(data.ids)
        } catch (error) {
          throw new Error(`${this.endpointURL} response caused error: ` + error)
        }
      }),
      catchError((err) => {
        console.error(err)
        this.snackBar.open('Error: Did not receive new id for sample set.', null, { duration: 2000 })
        return of([])
      })
    )
  }
}
