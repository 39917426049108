import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SampleSetService } from '../services/sample-set.service'
import { SampleSetQuery} from '../interfaces/sampleSetQuery'
import { SampleSet} from '../interfaces/sampleSet'
import { Router } from '@angular/router';
import { catchError } from 'rxjs/internal/operators/catchError';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs/internal/observable/of';
import { SampleSetDataSource } from './sample-set-data-source'
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { MatPaginator } from '@angular/material/paginator';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { tap } from 'rxjs/internal/operators/tap';

@Component({
  selector: 'app-sample-set-overview',
  templateUrl: './sample-set-overview.component.html',
  styleUrls: ['./sample-set-overview.component.sass']
})
export class SampleSetOverviewComponent implements OnInit {
  public dataSource: SampleSetDataSource
  public displayedColumns = ['shortSamplSeteId', 'description', 'tools'];


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private sampleSetService: SampleSetService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.dataSource = new SampleSetDataSource(this.sampleSetService, this.snackBar)
    this.dataSource.loadSamplesInitially({},'','asc',1,10) //'asc'=ascending
  }

  public openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  ngAfterViewInit():void {
    fromEvent(this.filter.nativeElement,'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    this.paginator.pageIndex = 0;
                    console.log('value of the filter ', this.filter.nativeElement.value)
                    this.dataSource.loadSamplesInitially({ $or: [{_id : {$regex : this.filter.nativeElement.value}}, {description:  {$regex : this.filter.nativeElement.value}}]},'','asc',1,10);
                })
            )
            .subscribe();
    this.paginator.page
    .pipe(
        tap(() => {
          console.log('page Event emitted, loadSamples() fired', this.paginator.pageIndex,this.paginator.pageSize)
          this.dataSource.loadSamples({},'','asc',this.paginator.pageIndex+1,this.paginator.pageSize)
        })
    )
    .subscribe();  }

  public editSampleSet(sampleSet: SampleSet):void{
    this.router.navigate(['sample-set/', sampleSet._id])
  }

}
