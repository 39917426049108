import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PostResponse } from '../interfaces/postResponse'
import { MeasurementEquipment } from '../interfaces/measurementEquipment';

@Injectable({
  providedIn: 'root'
})
export class MeasurementEquipmentService {

  private configUrl = '/api/measurement-equipment/';

  constructor(private http: HttpClient) { }

  updateMeasurementEquip(measurementEquipmentData: MeasurementEquipment): Promise<PostResponse> {
    return this.http.post<PostResponse>(this.configUrl + 'update', measurementEquipmentData).toPromise()
  }

  deleteMeasurementEquip(measurementEquipmentQuery: any): Promise<PostResponse> {
    return this.http.post<PostResponse>(this.configUrl + 'remove', measurementEquipmentQuery).toPromise()
  }

  getMeasurementEquip(measurementEquipmentQuery: any): Promise<PostResponse> {
    return new Promise<PostResponse>((resolve, reject) => { resolve({ 
      status: 'OK',
      measurementEquipment: [  
      {
      _id: 'test',
      description: 'Test',
      location: 'hier',
      files: [null],
      configurationInfo: 'Test',
      resolution:
          {
              cameraResolution_value: 1,
              cameraResolution_value_unit: 'Test',
              maxResolutionPerSquareMicroMeter: 1,
              minResolutionPerSquareMicroMeter:1
          },
      objectiveConfiguration: 
          {
              description: 'Test',
              okularMagnification: 1,
              objectiveMagnification: 1,
              scaleLength: 1,
              scalePixel: 1
          }
      }
      ]    

    })})
    //return this.http.post<PostResponse>(this.configUrl + 'query', measurementEquipmentQuery).toPromise()
  }
  
} 
