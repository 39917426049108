import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Sample } from '../interfaces/sample'
import { SampleService } from '../services/sample.service'
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SampleDataSource } from './sample-data-source';
import { MatPaginator } from '@angular/material/paginator';
import { tap } from 'rxjs/internal/operators/tap';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { QrCodeScannerComponent } from "../qr-code-scanner/qr-code-scanner.component";
import { Router } from '@angular/router';


@Component({
  selector: 'app-delete_dialog',
  templateUrl: './delete_dialog.html',
})
export class DeleteDialog implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public sample: Sample,
    public dialogRef: MatDialogRef<DeleteDialog>,
  ) { }

  public _id: String;
  public files: [Object]

  ngOnInit() {
    this._id = this.sample._id;
    this.files = this.sample.files
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-sample-overview',
  templateUrl: './sample-overview.component.html',
  styleUrls: ['./sample-overview.component.sass']
})
export class SampleOverviewComponent implements OnInit {

  public dataSource: SampleDataSource
  public displayedColumns = ['shortSampleId', 'description', 'tools'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;

  constructor(
    private sampleService: SampleService,
    private router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.dataSource = new SampleDataSource(this.sampleService, this.snackBar)
    this.dataSource.loadSamplesInitially({},'','asc',1,10)
  }

  ngAfterViewInit():void {
    fromEvent(this.filter.nativeElement,'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    this.paginator.pageIndex = 0;
                    console.log('value of the filter ', this.filter.nativeElement.value)
                    this.dataSource.loadSamplesInitially({ $or: [{_id : {$regex : this.filter.nativeElement.value}}, {description:  {$regex : this.filter.nativeElement.value}}]},'','asc',1,10);
                })
            )
            .subscribe();
    this.paginator.page
    .pipe(
        tap(() => {
          console.log('page Event emitted, loadSamples() fired', this.paginator.pageIndex,this.paginator.pageSize)
          this.dataSource.loadSamples({},'','asc',this.paginator.pageIndex+1,this.paginator.pageSize)
        })
    )
    .subscribe();  }

  
    public scanQRCode(): void {
      const dialogRef = this.dialog.open(QrCodeScannerComponent,{panelClass: 'qr-code-scanner-pane'});
  
    dialogRef.afterClosed().subscribe(result => {
      this.filter.nativeElement.value = result
      this.dataSource.loadSamplesInitially({_id : {$regex : this.filter.nativeElement.value}},'','asc',this.paginator.pageIndex+1,this.paginator.pageSize);    });}
  

  public editSample(sample: Sample): void {
    this.router.navigate(['sample/', sample._id])
  }

  public openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  public deleteSample(sampleId: String): void {
    //     this.sampleService.getSample({_id: sampleId}).then(data => { console.log(data.samples[0])// todo remove the nasty
    //     const dialogRef = this.dialog.open(DeleteDialog, data.samples[0]);
    //     dialogRef.afterClosed().subscribe(result => {
    //       if (result === true) {
    //         this.sampleService.deleteSample({_id: sampleId}).then(data=>{
    //           this.openSnackBar("Success: Deleted sample " + sampleId + ".")
    //           this.getAllSamples()
    //         }, error => {
    //           this.openSnackBar("Error: Could not delete sample.")
    //           console.error(error)
    //         })
    //       } 

    //   });
    // }, error => console.error(error)) // todo error handling
    //   }

  }

}