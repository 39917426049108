<mat-card>
  <mat-card-header>
    <mat-card-title>Generate Ids for samples</mat-card-title>
  </mat-card-header>

  <form [formGroup]="idGeneratorForm">

    <mat-form-field appearance="fill">
      <mat-label>amount of ids</mat-label>
      <input matInput type="number" formControlName="idAmount">
    </mat-form-field>
    <button style="margin-left: 25px" mat-raised-button  color="accent" [disabled]="!idGeneratorForm.valid" (click)="createIds()">Submit</button>

    <br>

    <!-- TODO: see https://gitlab.automated-testing.net/joshua_simon/sample_base/-/issues/73 -->
    <!-- <mat-slide-toggle (change)="toggleExpertMode()">expert mode</mat-slide-toggle>

    <br>

    <div *ngIf="expertMode" style="margin-top: 15px">
      <mat-form-field appearance="fill">
        <mat-label>Your IPv6 address range</mat-label>
        <input matInput type="string" formControlName="addressRange">
      </mat-form-field>
    </div>

    <br> -->



  </form>


  <div *ngIf="generatedIds.data.length > 1">
    <button mat-raised-button  (click)="downloadAsCsv()">Download as .csv</button>

    <table mat-table [dataSource]="generatedIds" class="mat-elevation-z8">
      <ng-container matColumnDef="index">
        <th class="tab_measurement" mat-header-cell *matHeaderCellDef>#</th>
        <td class="tab_measurement" mat-cell *matCellDef="let element; let i = index">
          {{i}}
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th class="tab" mat-header-cell *matHeaderCellDef> id </th>
        <td class="tab" mat-cell *matCellDef="let element">
          {{element}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="idColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: idColumns;"></tr>

    </table>
  </div>

</mat-card>
