import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Material } from '../interfaces/material'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { QrCodeScannerComponent } from "../qr-code-scanner/qr-code-scanner.component";
import { catchError } from 'rxjs/internal/operators/catchError';
import { Sample } from '../interfaces/sample'
import { flatMap, map, mergeMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

// services
import { SampleSetService } from '../services/sample-set.service'
import { SampleService } from '../services/sample.service'
import { IdGeneratorService } from '../services/id-generator.service'

// interfaces
import { SampleSet } from '../interfaces/sampleSet';
import { _File } from '../interfaces/_file';
import { UploadList, UploadListItem } from '../interfaces/uploadList';
import { ChecksumService } from '../services/checksum.service';

import { saveAs } from 'file-saver';
import { SampleDataSource } from '../sample-overview/sample-data-source';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-sample-set',
  templateUrl: './sample-set.component.html',
  styleUrls: ['./sample-set.component.sass']
})
export class SampleSetComponent implements OnInit {

  public sampleDataSource: SampleDataSource
  public sampleColumns = ['shortSampleId', 'description', 'status', 'tools'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filter') filter: ElementRef;


  public sampleSetForm: FormGroup;
  public newAssociatedSampleForm: FormGroup;
  public _id: String;
  public noFiles = true
  public availableFiles: [_File];
  public uploadList: UploadList
  private readonly endpointURL = '/api/sample-set';

  public uploadList$ = new BehaviorSubject<{
    fileId: String,
    sampleId: String,
    checksum: String,
    originalname: String
    file: File,
    uploadProgress?: number
  }[]>(null)
  private samples: Sample[]

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private sampleSetService: SampleSetService,
    private sampleService: SampleService,
    private router: Router,
    private idGeneratorService: IdGeneratorService,
    private checksumService: ChecksumService,
    public dialog: MatDialog,
    private http: HttpClient) {
    // todo: form validation
    this.sampleSetForm = new FormGroup({
      'description': new FormControl('', Validators.required),
      'creationTimestamp': new FormControl('', Validators.required),
      'associatedSamples': new FormControl([], Validators.nullValidator), // todo: enforce type empty string array
      'materials': new FormControl([], Validators.required)
    });
    this.newAssociatedSampleForm = new FormGroup({
      '_id': new FormControl([], Validators.required),
    })
    this.sampleDataSource = new SampleDataSource(this.sampleService, this.snackBar)

  }




  ngOnInit(): void {
    this.sampleDataSource = new SampleDataSource(this.sampleService, this.snackBar)

    this.route.params.subscribe(params => {



      if (params['_id'] != 'new') {
        this.sampleSetService.getSampleSet({ _id: params['_id'] }).pipe(
          map((sampleSets: SampleSet[]) => {
            // get first sample set
            if (sampleSets.length > 1) this.openSnackBar('Error: Multiple samples sets with the same unique identifier!')

            return sampleSets
          }),
          map((sampleSets: SampleSet[]) => {
            const sampleSet = sampleSets[0]
            this._id = sampleSet._id;

            // sample set set form values
            this.sampleSetForm.patchValue({
              description: sampleSet.description,
              creationTimestamp: sampleSet.creationTimestamp,
              materials: sampleSet.materials,
              associatedSamples: sampleSet.associatedSamples
            })
            if (sampleSet.files && sampleSet.files.length >= 1) {
              this.availableFiles = sampleSet.files
              this.noFiles = false
            }

            // associated sample
            let sampleInfo = []
            sampleSets[0].associatedSamples.forEach(element => {
              console.log('associated sample: ', element)
              sampleInfo.push(element)
            })

            return sampleInfo
          }),

        ).subscribe((res) => {
          const samplesOfSampleSetQuery = {
            "_id": {
              "$in": res
            }
          }
          console.log('samplesOfSampleSetQuery: ', samplesOfSampleSetQuery)
          this.sampleDataSource.loadSamplesInitially(samplesOfSampleSetQuery, '', 'asc', 1, null)
        })
      }
      else {
        this.setSampleSetId();
        this.sampleSetForm.setValue({
          description: '',
          creationTimestamp: this.getCurrentTimeStamp(),
          associatedSamples: [],
          materials: [ //TODO remove default value
            {
              code_DIN_EN_10027: '1.4404',
              shortname__DIN_EN_10027: 'Edelstahl',
              properties: 'nichtrostend austenitischer Stahl'
            }]

        })
      }
    })
  }


  // update sample set
  public submit = function (): void {
    const sampleSetData = {
      _id: this._id,
      description: this.sampleSetForm.value.description,
      associatedSamples: this.sampleSetForm.value.associatedSamples,
      materials: this.sampleSetForm.value.materials,
      creationTimestamp: this.sampleSetForm.value.creationTimestamp,
      lastEditedTimestamp: this.sampleSetForm.value.lastEditedTimestamp
    }
    this.sampleSetService.updateSampleSet(sampleSetData).subscribe(
      data => {
        this.openSnackBar('Succes: Saved sample set to database.')
      },
      error => {
        console.error(error);
        this.openSnackBar('Error: Could not save sample set.')
      }
    )
  }


  // load associated samples






  // UI related vars
  public panelOpenState = false; // associated sample expansion panel
  // chips
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public displayedColumns: String[] = ['originalname', 'fileId', 'download'];
  public fileUploadColumns: String[] = ['originalname', 'checksum', 'status']

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private setSampleSetId(): void {
    this.idGeneratorService.getNewId(1,'').subscribe(ids => { this._id = ids[0] })
  }

  private getCurrentTimeStamp(): String {
    return new Date().toISOString()
  }

  // add chip material
  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // add material
    if ((value || '').trim()) {

      // todo load available materials for validation and info completion

      // todo Validation

      this.sampleSetForm.value.materials.push({ code_DIN_EN_10027: value.trim() });
    }

    // reset input value
    if (input) {
      input.value = '';
    }
  }

  public editSample(_id: string) {
    this.router.navigate(['sample/', _id])
  }


  public remove(material: Material): void {
    const index = this.sampleSetForm.value.materials.indexOf(material);

    if (index >= 0) {
      this.sampleSetForm.value.materials.splice(index, 1);
    }
  }

  public openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  public removeSampleFromSet(_id: string): void {
    this.sampleSetForm.value.associatedSamples = this.sampleSetForm.value.associatedSamples.filter(sampleId => sampleId !== _id)
    this.openSnackBar('Warning: Did not delete the sample, only removed it from this set.')

    const samplesOfSampleSetQuery = {
      "_id": {
        "$in": this.sampleSetForm.value.associatedSamples
      }
    }
    this.sampleDataSource.loadSamples(samplesOfSampleSetQuery, '', 'asc', 1, null)
    this.submit()
  }

  public addNewAssociatedSample(_id: string): void {
    this.sampleService.updateSamples([{ _id: _id }]).pipe(
      mergeMap(() => {
        this.sampleSetForm.value.associatedSamples.push(this.newAssociatedSampleForm.value._id)
        const sampleSetData = {
          _id: this._id,
          description: this.sampleSetForm.value.description,
          associatedSamples: this.sampleSetForm.value.associatedSamples,
          materials: this.sampleSetForm.value.materials,
          creationTimestamp: this.sampleSetForm.value.creationTimestamp,
          lastEditedTimestamp: this.sampleSetForm.value.lastEditedTimestamp
        }
        return this.sampleSetService.updateSampleSet(sampleSetData)
      }),
      mergeMap(() => {
        this.newAssociatedSampleForm.reset()
        return this.sampleSetService.getSampleSet({ _id: this._id })
      })
    ).subscribe( sampleSets => {
      const samplesOfSampleSetQuery = {
        "_id": {
          "$in": sampleSets[0].associatedSamples
        }
      }
      this.sampleDataSource.loadSamples(samplesOfSampleSetQuery, '', 'asc', 1, null)
    })
  }

  public download = function (fileId: String, originalname: String): void {

    let thefile = {};
    this.openSnackBar('Loading: Please wait until the file is downloaded...')
    this.sampleService.downloadFile(fileId, originalname)
      .subscribe((data: HttpResponse<any>) => {
        this.downloadFile(data, originalname);
      }
        , error => { console.error(error), this.openSnackBar("Error: Could not download file.") })
  }

  public downloadFile(data: any, filename?: String) {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
    if (filename) saveAs(blob, filename); else saveAs(blob, null)

  }

  public openDialog() {
    const dialogRef = this.dialog.open(QrCodeScannerComponent, { panelClass: 'qr-code-scanner-pane' });

    dialogRef.afterClosed().subscribe(result => {
      this.newAssociatedSampleForm.setValue({ _id: result });
    }, error => console.error(error));
  }

  public scanQRCode() { this.openDialog() }


  private async createUploadList(fileList: FileList): Promise<any> {
    this.uploadList$.next([])
    this.openSnackBar('Please wait while files are prepared for the upload.')
    for (const file of Array.from(fileList)) {
      const newFileId = await this.idGeneratorService.getNewIdPromise() //TODO get rid of this and remove this service function as well, all should use getNewId
      const checksum = await this.checksumService.returnChecksum(file)
      this.uploadList$.next([...this.uploadList$.getValue(), {
        'fileId': newFileId,
        'sampleId': this._id,
        'checksum': checksum,
        'originalname': file.name,
        'file': file
      }])

    }
    return Promise.resolve(this.uploadList$.getValue())
  }

  public async setFile(fileList: FileList) {
    const uploadList = await this.createUploadList(fileList)
    let okCounter = 0
    uploadList.forEach(element => {
      this.sampleSetService.upload(element).subscribe((uploadProgress: number) => {
        if (uploadProgress == null) uploadProgress = 0
        else if (uploadProgress == 200) {
          uploadProgress = 100;
          okCounter = okCounter + 1;
          if (okCounter == 2 * (uploadList.length)) {
            this.openSnackBar('Upload of all files successfull.')
            setTimeout(() => { window.location.reload() }, 3000)
          }
        }
        else if (uploadProgress == -1) { uploadProgress = 0; this.openSnackBar('Error uploading file') }
        else {
          try {
            let valueOfUploadList$ = this.uploadList$.getValue()
            valueOfUploadList$.filter((file) => { return file.originalname == element.originalname })[0].uploadProgress = uploadProgress
            this.uploadList$.next(valueOfUploadList$)

          } catch (err) { this.openSnackBar('Error while calculating upload progess'); uploadProgress = 0 }
        }
      })
    });

  }
}



