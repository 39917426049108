<mat-card>

  <input style="display:none" type="file" class="file-input"
  (change)="incomingfile($event)" accept=".xlsx" #fileUpload>

  <div class="file-upload">

    {{fileName || "No file uploaded yet."}}

    <button mat-mini-fab color="secondary" class="upload-btn" (click)="fileUpload.click()">
      <mat-icon>attach_file</mat-icon>
    </button>
  </div>

<div *ngIf="extractedJson.length != 0">

<div style="padding-bottom: 30px;">
  <p-panel header="Table" [toggleable]="true" [collapsed]="true">
    <p-table #dt [columns]="cols" [value]="extractedJson">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-panel>
  
  <p-panel header="JSON" [toggleable]="true" [collapsed]="true">
      <pre [innerHTML]="extractedJson | prettyjson: [true, 3]"></pre>
  </p-panel>
  

</div>


<mat-divider></mat-divider>

<p class="mat-body">Reference in database</p>


<form [formGroup]="csvIngestForm">
  <mat-form-field appearance="fill">
    <mat-label>Sample set</mat-label>
    <mat-select formControlName="selectedSampleSet">
      <mat-option *ngFor="let sampleSet of availableSampleSets" [value]="sampleSet._id">
        {{sampleSet.description}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill">
    <mat-label>Sample</mat-label>
      <mat-select formControlName="selectedSample">
        <mat-option *ngFor="let sample of availableSamples" [value]="sample._id">
          {{sample.description}}
        </mat-option>
      </mat-select>

  </mat-form-field>
  <br>
  <mat-divider></mat-divider>
  <br>
  <p class="mat-body">Measurment information</p>
  <mat-form-field appearance="fill">
    <mat-label>identifier</mat-label>
    <mat-select formControlName="identifierColumn">
      <mat-option *ngFor="let col of cols" [value]="col.header">
        {{col.field}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill">
    <mat-label>file id</mat-label>
    <mat-select formControlName="fileIdColumn">
      <mat-option *ngFor="let col of cols" [value]="col.header">
        {{col.field}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill">
    <mat-label>measurment</mat-label>
    <mat-select formControlName="measurmentColumn">
      <mat-option *ngFor="let col of cols" [value]="col.header">
        {{col.field}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill">
    <mat-label>measured value</mat-label>
    <mat-select formControlName="measuredValueColumn">
      <mat-option *ngFor="let col of cols" [value]="col.header">
        {{col.field}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill">
    <mat-label>unit</mat-label>
    <mat-select formControlName="unitColumn">
      <mat-option *ngFor="let col of cols" [value]="col.header">
        {{col.field}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

<button mat-raised-button color="accent" [disabled]="!csvIngestForm.valid" (click)="digest()">Save
  sample</button>

</div>

 </mat-card> 
