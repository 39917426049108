<mat-card>
    <mat-card-header>
        <h1 class="mat-h1" style="position: centered;">Manage the lifecycle of your experimental data from planning to publication.
        </h1>
    </mat-card-header>
    <mat-card-content>
        <p>
            Our platform is designed to help scientific professionals like you ensure the quality of your data pipeline with ease and confidence. With our powerful tool, you can create your own data structures as you go and work against indicators of statistical significance, making it easier than ever to conduct your experiments with precision and accuracy.
        </p>
        <p>
          One of the key features of our platform is the ability to automatically ingest your data directly from your measurement equipment to the database. This means you can save time and reduce the risk of errors by eliminating the need for manual data entry. Plus, our platform makes it easy to see your progress on a dashboard, so you can track your results and make informed decisions about your experiment.
        </p>
        <p>
          We understand that working on complex data sets can be challenging, especially when working in interdisciplinary teams. That's why our platform is designed to help you share selected data sets and meta information with your team, ensuring that everyone is on board and working towards a common goal.
        </p>
        <mat-grid-list cols="3" rowHeight="1:1">
            <mat-grid-tile>
                <mat-card class="example-card">
                    <mat-card-header>
                      <mat-card-title>Data Import</mat-card-title>
                      <mat-card-subtitle>Extensive ingest capabilities</mat-card-subtitle>
                    </mat-card-header>
                    <img mat-card-image src="assets/data-icon.svg" style="width:250px" alt="Photo of a Shiba Inu">
                    <mat-card-content>
                      <p>
                        We currently offer .csv and .xml ingest tools to first display, manipulate and then store your data in an object data base. Also we offer custom ingest tools that can read your proprietery file format and parse it into a object structure.
                      </p>
                    </mat-card-content>
                  </mat-card>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>UI Query builder</mat-card-title>
                  <mat-card-subtitle>Enable advanced data base queries with the help of a simple UI</mat-card-subtitle>
                </mat-card-header>
                <img mat-card-image src="assets/database-upload-icon.svg" style="width:250px" alt="Photo of a Shiba Inu">
                <mat-card-content>
                  <p>
                    You can explore your data through extensive queries. Export queries or the resulting data and start create plotting exactly what you are looking for.
                  </p>
                </mat-card-content>
              </mat-card>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-card class="example-card">
                    <mat-card-header>
                      <mat-card-title>Open-source backend</mat-card-title>
                      <mat-card-subtitle>Written to openAPI specification</mat-card-subtitle>
                    </mat-card-header>
                    <img mat-card-image src="assets/opensource-icon.svg"  style="width:250px" alt="Photo of a Shiba Inu">
                    <mat-card-content>
                      <p>
                        Documentation and API libraries are automatically created with swagger, see ???.
                      </p>
                    </mat-card-content>
                  </mat-card>
            </mat-grid-tile>
          </mat-grid-list>
    </mat-card-content>




</mat-card>
  


<!-- 

Plan experiments, acquire data share insights.

Betreuen Sie den Lebenszyklus ihrer Experimentdaten von der Planung bis zum statischen Hosten mit DOI.





Experiment data management based on a database of objects
Flat data structure
Powerful API
Enable transparent research based on high quality evidence which is easily accessible

Alternatives

electronic lab notebooks -> bio demo https://www.collaborativedrug.com/benefits/eln/
 -->
