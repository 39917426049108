<mat-toolbar>
  <button mat-button [matMenuTriggerFor]="menu">Menu</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="/sample-set-overview">sample set overview</button>
  <button mat-menu-item routerLink="/sample-set/new">add new sample set</button>
  <button mat-menu-item routerLink="/sample-overview">sample overview</button>
  <button mat-menu-item routerLink="/generate-ids">generate ids</button>
  <!-- <button mat-menu-item routerLink="/measurement-equip">measurement equipment</button> -->
  <button mat-menu-item routerLink="/csv-ingest">csv ingest</button>

</mat-menu>
  <span>
    <button routerLink="/home">
      <img style="height:50px" src="assets/sample_base_logo.svg" alt="sample base logo">

    </button>
  </span>
  <span class="example-spacer"></span>
  <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
    <mat-icon>share</mat-icon>
  </button>
</mat-toolbar>
