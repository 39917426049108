// Angular base modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http'
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// UI building blocks from Angular Material and PrimeNG
import { MaterialModule } from './material.module';
import { PrimengModule } from './primeng.module';

// other third party modules
import { BarcodeScannerLivestreamModule } from "ngx-barcode-scanner";

// Angular components
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SampleSetComponent } from './sample-set/sample-set.component'
import { SampleSetOverviewComponent} from './sample-set-overview/sample-set-overview.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SampleComponent } from './sample/sample.component';
import { QrCodeScannerComponent } from './qr-code-scanner/qr-code-scanner.component';
import { DeleteDialog, SampleOverviewComponent } from './sample-overview/sample-overview.component';
import { IdGeneratorComponent } from './id-generator/id-generator.component';
import { MeasurementEquipComponent } from './measurement-equip/measurement-equip.component';
import { CsvTableComponent } from './csv-table/csv-table.component';

// Angular services
import { SampleSetService } from './services/sample-set.service'
import { IdGeneratorService } from './services/id-generator.service'

// custom pipes
import { PrettyJsonPipe } from './prettyjson.pipe';
import { HomeComponent } from './home/home.component';


@NgModule({

  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SampleSetComponent,
    SampleSetOverviewComponent,
    DeleteDialog,
    PageNotFoundComponent,
    SampleComponent,
    QrCodeScannerComponent,
    SampleOverviewComponent,
    IdGeneratorComponent,
    MeasurementEquipComponent,
    CsvTableComponent,
    PrettyJsonPipe,
    HomeComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    PrimengModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BarcodeScannerLivestreamModule
  ],
  providers: [ IdGeneratorService, SampleSetService, HttpClientModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
