<mat-card>

  <!-- header with id and timestamp -->
  <mat-card-header>
    <mat-card-title>Sample</mat-card-title>
    <!-- <mat-card-subtitle>id: {{_id}}</mat-card-subtitle> -->
    <mat-card-subtitle>ASCI safe: {{ascisafe_id}}</mat-card-subtitle>
    <mat-card-subtitle>creation date: {{sampleForm.value.creationTimestamp}}</mat-card-subtitle>
    <!-- TODO creation vs. last time saved -->
    <button mat-raised-button color="accent" [disabled]="!sampleForm.valid" (click)="submit()">Save
      sample</button>
    <div style="margin-left:10px"></div>
    <button mat-raised-button (click)="delete(_id)">Delete sample</button>

  </mat-card-header>


  <!-- sampleForm, measurment table and associated files -->


  <!-- sampleForm -->
  <form [formGroup]="sampleForm">

    <mat-form-field appearance="fill">
      <mat-label>description</mat-label>
      <input matInput formControlName="description">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>status</mat-label>
      <input matInput formControlName="status">
    </mat-form-field>

    <br>

    <mat-form-field class="example-chip-list">
      <mat-chip-list #chipListMaterial aria-label="material selection">
        <mat-chip *ngFor="let material of sampleForm.value.materials" [selectable]="selectable"
          [removable]="removable_material" (removed)="remove_material(material)">
          {{material.code_DIN_EN_10027}}
          <mat-icon matChipRemove *ngIf="removable_material">cancel</mat-icon>
        </mat-chip>
        <input placeholder="materials" [matChipInputFor]="chipListMaterial"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add_material($event)">
      </mat-chip-list>
    </mat-form-field>


    <mat-form-field class="example-chip-list">
      <mat-chip-list #chipListEtching aria-label="etching selection">
        <mat-chip *ngFor="let etching of sampleForm.value.etching" [selectable]="selectable"
          [removable]="removable_etching" (removed)="remove_etching(etching)">
          {{etching.friendly_name}}
          <mat-icon matChipRemove *ngIf="removable_etching">cancel</mat-icon>
        </mat-chip>
        <input placeholder="post processing" [matChipInputFor]="chipListEtching"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add_postProcessing($event)">
      </mat-chip-list>
    </mat-form-field>

  </form>

  <mat-divider></mat-divider>

  <!-- process parameter table -->
  <div *ngIf="!noProcessparameters">
    <table mat-table [dataSource]="processParameterDataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="process_parameter_identifier">
        <th class="tab" mat-header-cell *matHeaderCellDef>identifier</th>
        <td class="tab" mat-cell *matCellDef="let element">
          <input [(ngModel)]="element.process_parameter_identifier" placeholder="identifier" ngDefaultControl matInput type="text">
        </td>
      </ng-container>

      <ng-container matColumnDef="process_parameter_file_id" >
        <th class="tab" mat-header-cell *matHeaderCellDef>file id</th>
        <td  class="tab" mat-cell *matCellDef="let element">
          <mat-select [(ngModel)]="element.process_parameter_file_id" placeholder="Select the corresponding file">
          <mat-option *ngFor="let file of availableFiles" [value]="file.fileId">
            {{file.originalname}}
          </mat-option>
        </mat-select>
        </td>
      </ng-container>

      <ng-container matColumnDef="process_parameter">
        <th class="tab_measurement" mat-header-cell *matHeaderCellDef> process parameter </th>
        <td class="tab_measurement" mat-cell *matCellDef="let element">
          <mat-select [(ngModel)]="element.process_parameter" ngDefaultControl>
            <mat-option value="laser_power">laser power</mat-option>
            <mat-option value="laser_scan_velocity">laser scan velocity</mat-option>
            <mat-option value="hatch_distance">hatch distance</mat-option>
            <mat-option value="focal_diameter">focal diameter</mat-option>
            <mat-option value="focal_diameter">focal diameter</mat-option>
            <mat-option value="powder_heigth">powder height</mat-option>
            <mat-option value="laser_scan_vector_length">laser scan vector length</mat-option>
            <mat-option value="spot_size">spot size</mat-option>
            <mat-option value="build-plate-temperature">build plate temperature</mat-option>
            <mat-option value="overlap">overlap</mat-option>
            <mat-option value="scanning_overlap">scanning wavelength</mat-option>
            <mat-option value="laser_rate">laser rate</mat-option>
            <mat-option value="tape_material">tape material</mat-option>
            <mat-option value="machining_width">machining width</mat-option>
          </mat-select>
        </td>
      </ng-container>

      <ng-container matColumnDef="process_parameter_value">
        <th class="tab" mat-header-cell *matHeaderCellDef> value </th>
        <td class="tab" mat-cell *matCellDef="let element" [(ngModel)]="element.process_parameter_value"
          ngDefaultControl>
          <input [(ngModel)]="element.process_parameter_value" matInput type="text">
        </td>
      </ng-container>

      <ng-container matColumnDef="process_parameter_value_unit">
        <th class="tab_unit" mat-header-cell *matHeaderCellDef> unit </th>
        <td class="tab_unit" mat-cell *matCellDef="let element">
          <mat-select [(ngModel)]="element.process_parameter_value_unit" ngDefaultControl>
            <mat-option value="micro-meter">µm</mat-option>
            <mat-option value="watt">W</mat-option>
            <mat-option value="meter-per-second">m/s</mat-option>
            <mat-option value="nano-meter">nm</mat-option>
            <mat-option value="milli-meter">mm</mat-option>
            <mat-option value="joule">J</mat-option>
            <mat-option value="percentage">%</mat-option>
            <mat-option value="hertz">Hz</mat-option>
            <mat-option value="degree-celsius">°C</mat-option>
          </mat-select>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="process_parameterColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: process_parameterColumns;"></tr>

    </table>
  </div>
  <button mat-raised-button color="accent" (click)="addNewProcessParameter()">Add process parameter</button>

  <br>
  <mat-divider></mat-divider>

  <!-- measurement table -->
  <div *ngIf="!noMeasurments">

    <table mat-table [dataSource]="measurementDataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="measurement_identifier">
        <th class="tab" mat-header-cell *matHeaderCellDef>identifier</th>
        <td class="tab" mat-cell *matCellDef="let element">
          <input [(ngModel)]="element.measurement_identifier" ngDefaultControl matInput type="text" placeholder="Set a friendly name">
        </td>
      </ng-container>

      <ng-container matColumnDef="measurement_equip_profile" >
        <th class="tab" mat-header-cell *matHeaderCellDef style="width: 50%">measurement equip</th>
        <td  class="tab" mat-cell *matCellDef="let element" style="width: 50%">
          <mat-select [(ngModel)]="element.measurement_equip_profile" placeholder="Select measurement equip profile">
          <mat-option *ngFor="let equip of availableMeasurementEquipProfiles" [value]="equip.measurementEquipId">
            {{file.description}
          </mat-option>
        </mat-select>
        </td>
      </ng-container>

      <ng-container matColumnDef="measurement_file_id" >
        <th class="tab" mat-header-cell *matHeaderCellDef style="width: 50%">file id</th>
        <td  class="tab" mat-cell *matCellDef="let element" style="width: 50%">
          <mat-select [(ngModel)]="element.measurement_file_id" placeholder="Select the corresponding file">
          <mat-option *ngFor="let file of availableFiles" [value]="file.fileId">
            {{file.originalname}}
          </mat-option>
        </mat-select>
        </td>
      </ng-container>


      <ng-container matColumnDef="measurement">
        <th class="tab_measurement" mat-header-cell *matHeaderCellDef> measurement </th>
        <td class="tab_measurement" mat-cell *matCellDef="let element">
          <mat-select [(ngModel)]="element.measurement" ngDefaultControl placeholder="Select kind of measurement">
            <mat-option value="hatched_balling">hatched balling</mat-option>
            <mat-option value="single_balling">single balling</mat-option>
            <mat-option value="minimum_distance_between_top_and_bottom">minimum distance between top and bottom</mat-option>
            <mat-option value="mean_distance_between_top_and_bottom">mean distance between top and bottom</mat-option>
            <mat-option value="melt pool width">meltpool width</mat-option>
            <mat-option value="melt pool depth">meltpool depth</mat-option>
            <mat-option value="mean_hatched_melt_pool_depth">mean hatched melt pool depth</mat-option>
            <mat-option value="min_hatched_melt_pool_depth">minimum hatched melt pool depth</mat-option>
            <mat-option value="coordinates">coordinates</mat-option>
            <mat-option value="single melt pool width">single meltpool width</mat-option>
            <mat-option value="single melt pool depth">single meltpool depth</mat-option>
            <mat-option value="meltpool half width">meltpool half width</mat-option>
            <mat-option value="distance to interface">distance to interface</mat-option>
            <mat-option value="nickel alloy component">nickel alloy component</mat-option>
            <mat-option value="copper alloy component">copper alloy component</mat-option>
            <mat-option value="zirconium alloy component">zirconium alloy component</mat-option>
            <mat-option value="silicon alloy component">silicon alloy component</mat-option>
            <mat-option value="iron alloy component">iron alloy component</mat-option>
            <mat-option value="phosphorus alloy component">phosphorus alloy component</mat-option>
            <mat-option value="chromium alloy component">chromium alloy component</mat-option>
            <mat-option value="carbon alloy component">carbon alloy component</mat-option>
            <mat-option value="hydrogen alloy component">hydrogen alloy component</mat-option>
            <mat-option value="oxygen alloy component">oxygen alloy component</mat-option>
            <mat-option value="titanium alloy component">titanium alloy component</mat-option>
            <mat-option value="molybdenum alloy component">molybdenum alloy component</mat-option>
            <mat-option value="aluminium alloy component">aluminium alloy component</mat-option>
            <mat-option value="sulfur alloy component">sulfur alloy component</mat-option>
            <mat-option value="manganese alloy component">manganese alloy component</mat-option>
            <mat-option value="cobalt alloy component">cobalt alloy component</mat-option>
            <mat-option value="temperature">temperature</mat-option>      
            <mat-option value="thermal-conductivity">thermal conductivity</mat-option>          
            <mat-option value="mass-specific-heat-capacity">mass specific heat capactiy</mat-option>
            <mat-option value="density">density</mat-option>
            <mat-option value="relative-density">relative density</mat-option>
            <mat-option value="curvature">curvature</mat-option>
            <mat-option value="curvature_change">curvature change</mat-option>
            <mat-option value="y_coordinate_on_image">y coordinate on image</mat-option>
            <mat-option value="young_modulus">young modulus</mat-option>
            <mat-option value="offset_yield">offset yield</mat-option>
            <mat-option value="tensile_strength">tensile strength</mat-option>
            <mat-option value="uniform_elongation">uniform elongation</mat-option>
            <mat-option value="elongation_at_break">elongation at break</mat-option>
            <mat-option value="start_length">start length</mat-option>
            <mat-option value="start_diameter">start diameter</mat-option>
            <mat-option value="load_cycles">load cycles</mat-option>
            <mat-option value="nominal_bending_stress">nominal beding stress</mat-option>
            <mat-option value="cycle_frequency">cycle frequency</mat-option>
            <mat-option value="bending_moment">bending moment</mat-option>
            <mat-option value="resisting-moment-against-bending">resisting moment against bending</mat-option>

          </mat-select>
        </td>
      </ng-container>

      <ng-container matColumnDef="measurement_value">
        <th class="tab" mat-header-cell *matHeaderCellDef> measured value </th>
        <td class="tab" mat-cell *matCellDef="let element" [(ngModel)]="element.measurement_value" ngDefaultControl>
          <input [(ngModel)]="element.measurement_value" matInput type="text">
        </td>
      </ng-container>

      <ng-container matColumnDef="measurement_value_unit">
        <th class="tab_unit" mat-header-cell *matHeaderCellDef> unit </th>
        <td class="tab_unit" mat-cell *matCellDef="let element">
          <mat-select [(ngModel)]="element.measurement_value_unit" ngDefaultControl placeholder="Set unit of measurement">
            <mat-option value="micro-meter">µm</mat-option>
            <mat-option value="nano-meter">nm</mat-option>
            <mat-option value="mili-meter">mm</mat-option>
            <mat-option value="centi-meter">cm</mat-option>
            <mat-option value="percentage">%</mat-option>
            <mat-option value="kilogramm-per-cubic-meter">kg/m³</mat-option>
            <mat-option value="degree-celsius">°C</mat-option>
            <mat-option value="watt-per-meter-kelvin">W/(mK)</mat-option>
            <mat-option value="joule-per-kilogramm-kelvin">J/(kgK)</mat-option>
            <mat-option value="1-per-mm">1/mm</mat-option>
            <mat-option value="pixel">pixel</mat-option>
            <mat-option value="newton-per-square-mili-meter">N/mm²</mat-option>
            <mat-option value="pascal">Pa</mat-option>
            <mat-option value="mega-pascal">MPa</mat-option>
            <mat-option value="hertz">Hz</mat-option>
            <mat-option value="amount">amount</mat-option>
            <mat-option value="newton-meter">Nm</mat-option>
            <mat-option value="mili-meter-mili-meter-mili-meter">mm³</mat-option>
            <mat-option value="boolean">boolean</mat-option>
          </mat-select>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="measurement_value_unit">
        <th class="tab_unit" mat-header-cell *matHeaderCellDef> unit </th>
        <td class="tab_unit" mat-cell *matCellDef="let element">
          <mat-select [(ngModel)]="element.measurement_value_unit" ngDefaultControl>
            <mat-option value="micro-meter">µm</mat-option>
          </mat-select>
        </td>
      </ng-container> -->

      <!-- <ng-container matColumnDef="tools">
        <th class="tab" mat-header-cell *matHeaderCellDef></th>
        <td class="tab" mat-cell *matCellDef="let element">
          <button mat-button color="accent" (click)="removeMesurement(element.fileId, element.originalname)">Remove</button>
        </td>
      </ng-container>
      -->

      <tr mat-header-row *matHeaderRowDef="measurementColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: measurementColumns;"></tr>

    </table>
  </div>

  <button mat-raised-button color="accent" (click)="addNewMeasurement()">Add measurment</button>

  <mat-divider></mat-divider>

  <div *ngIf="!noFiles">
    <table mat-table [dataSource]="availableFiles" class="mat-elevation-z8">

      <ng-container matColumnDef="originalname">
        <th class="tab" mat-header-cell *matHeaderCellDef> originalname </th>
        <td class="tab" mat-cell *matCellDef="let element"> {{element.originalname}} </td>
      </ng-container>

      <ng-container matColumnDef="fileId">
        <th class="fileId" mat-header-cell *matHeaderCellDef> fileId </th>
        <td class="fileId" mat-cell *matCellDef="let element"> {{element.fileId}} </td>
      </ng-container>

      <ng-container matColumnDef="download">
        <th class="tab" mat-header-cell *matHeaderCellDef></th>
        <td class="tab" mat-cell *matCellDef="let element">
          <button mat-button color="accent" (click)="download(element.fileId, element.originalname)">Download
            file</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <br>

  <input (change)="setFile($event.target.files)" type="file" name="sampleFile" id="file" multiple>
  <div *ngIf="(uploadList$ | async) as filesToBeUploaded">
    <table mat-table [dataSource]="uploadList$ | async" class="mat-elevation-z8">
      <ng-container matColumnDef="originalname">
        <th class="tab" mat-header-cell *matHeaderCellDef> originalname </th>
        <td class="tab" mat-cell *matCellDef="let element"> {{element.originalname}} </td>
      </ng-container>
      <ng-container matColumnDef="checksum">
        <th class="tab" mat-header-cell *matHeaderCellDef> local checksum </th>
        <td class="tab" mat-cell *matCellDef="let element"> {{element.checksum}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th class="fileId" mat-header-cell *matHeaderCellDef> upload status </th>
        <td class="fileId" mat-cell *matCellDef="let element"> {{element.uploadProgress}}% </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="fileUploadColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: fileUploadColumns;"></tr>
    </table>

  </div>
  <ng-template #noFilesUploading>no files</ng-template>
  <!-- <label for="input-file-id" class="md-button md-raised md-primary">Choose Files</label>   -->

  <br>
  <mat-divider></mat-divider>

  <mat-slide-toggle (change)="toggleExpertMode()">expert mode</mat-slide-toggle>
  <div *ngIf="expertMode">
    <mat-form-field style="width:100%" appearance="fill">
      <mat-label>read the data object as json</mat-label>
      <textarea matInput disabled="true" [(ngModel)]="sampleFormAsText"></textarea>
    </mat-form-field>
  </div>


</mat-card>