import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, of } from "rxjs";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { catchError, finalize } from "rxjs/operators";
import { Sample } from "../interfaces/sample";
import { SampleQuery } from "../interfaces/sampleQuery";
import { SampleService } from "../services/sample.service";


export class SampleDataSource implements DataSource<Sample> {


    private sampleSubject = new BehaviorSubject<Sample[]>([]);
    private sampleAmountSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public amount$ = this.sampleAmountSubject.asObservable();

    constructor(private sampleService: SampleService, private snackBar: MatSnackBar) {}

    connect(collectionViewer: CollectionViewer): Observable<Sample[]> {
        return this.sampleSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.sampleSubject.complete();
        this.loadingSubject.complete();
        this.sampleAmountSubject.complete();
    }

    loadSamples(sampleQuery: SampleQuery,
        filter = '',
        sortOrder = 'asc',
        pageNumber = 1,
        pageSize= 10
        ) {

        this.loadingSubject.next(true);
          this.sampleService.getSamples(sampleQuery, filter, 
            sortOrder, pageNumber, pageSize).pipe(
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(samples => {
            this.sampleSubject.next(samples)
            console.log('loadSamples - sampleSubject: ', this.sampleSubject)
        });

    }    
    loadSamplesInitially(sampleQuery: SampleQuery,
        filter = '',
        sortOrder = 'asc', //'asc'=ascending
        pageNumber = 1,
        pageSize= 10
        ) {

        this.loadingSubject.next(true);
        this.sampleService.getSamples(sampleQuery, filter, 
            sortOrder, pageNumber, pageSize).pipe(
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(samples => {
            this.sampleSubject.next(samples)
            console.log('loadSamplesInitially - sampleSubject: ', this.sampleSubject)
        });

        this.sampleService.getSamples(sampleQuery,filter,null, 1, null)
        .subscribe(samples => {
            this.sampleAmountSubject.next(samples.length)
        })
    }    
}

