<mat-card>
    <mat-card-header>
        <mat-card-title>Sample Set Overview</mat-card-title>
        <mat-card-subtitle>all available sample sets</mat-card-subtitle>
    </mat-card-header>
    <mat-form-field>
        <input matInput placeholder="Search sample sets" #filter>
    </mat-form-field>


    <div style="position: relative;">
        <div style="display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.1);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;" *ngIf="dataSource.loading$ | async">
            <mat-progress-spinner color="accent" mode="indeterminate" diameter="50">
            </mat-progress-spinner>
        </div>
        <mat-table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="shortSamplSeteId">
                <th mat-header-cell *matHeaderCellDef>short id</th>
                <td mat-cell *matCellDef="let sampleSet">{{sampleSet._id.substring(sampleSet._id.length-9)}}</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>description</th>
                <td mat-cell *matCellDef="let sampleSet">{{sampleSet.description}}</td>
            </ng-container>

            <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let sampleSet">
                    <button mat-button color="accent" (click)="editSampleSet(sampleSet)">Edit sample set</button>
                    <button mat-button color="accent" (click)="delete(sampleSet._id)">Remove sample set</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </mat-table>


    </div>

    <mat-paginator [length]="dataSource.amount$ | async" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]">
    </mat-paginator>

</mat-card>