import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PostResponse } from '../interfaces/postResponse'
import { SampleSet } from '../interfaces/sampleSet';
import { SampleSetQuery } from '../interfaces/sampleSetQuery';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs/internal/observable/of';
import { UploadListItem } from '../interfaces/uploadList';
import { MatSnackBar } from "@angular/material/snack-bar";

export interface SampleSetResponse {
  body: any,
  status: any,
  sampleSets: any,
  error: any
}

@Injectable({
  providedIn: 'root'
})
export class SampleSetService {

  private endpointURL = '/api/sample-set/';

  constructor(private http: HttpClient,  private snackBar: MatSnackBar) { }

  updateSampleSet(sample_set_data: any): Observable<any> {
    return this.http.post<PostResponse>(this.endpointURL + 'update', sample_set_data).pipe(
      catchError((error)=>{
        this.snackBar.open('Error: Could not update sample set',null,{duration:2000})
        console.error(error);
        return of(false)
      }),
      map(data => {
        // if(!data.error){
        //   return of(true)
        // }
  })
    )
  }

  deleteSampleSet(sampleSetQuery: SampleSetQuery): Promise<PostResponse> {
    return this.http.post<PostResponse>(this.endpointURL + 'remove', sampleSetQuery).toPromise()
  }

  getSampleSet(
  sampleSetQuery: SampleSetQuery = {},
  filter: string = '',
  sortOrder: string = 'asc',
  pageNumber: number = 1,
  pageSize: number = 1
): Observable<SampleSet[]> {
  return this.http.get<SampleSetResponse>(`${this.endpointURL}`, {
    params: new HttpParams()
      .set('sampleSetQuery', <string>JSON.stringify(sampleSetQuery))
      .set('filter', <string>filter)
      .set('sortOrder', <string>sortOrder)
      .set('pageNumber', <number>pageNumber)
      .set('pageSize', <number>pageSize)
  }).pipe(
    map(res => res.sampleSets),
    catchError((error)=>{
      this.snackBar.open('Error: Could not get sample sets from server',null,{duration:2000})
      console.error(error);
      return of([])
    })
  )
}

upload(uploadListItem: UploadListItem): Observable<Number> {
  const uploadForm = new FormData()
    uploadForm.append('originalName', <string>uploadListItem.originalname)
    uploadForm.append('sampleId', <string>uploadListItem.sampleId)
    uploadForm.append('checksum', <string>uploadListItem.checksum)
    uploadForm.append('fileId', <string>uploadListItem.fileId)
    uploadForm.append(<string>uploadListItem.fileId, <File>uploadListItem.file)
    return this.http.post<PostResponse>(`${this.endpointURL}/upload`, uploadForm, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((data) => {
        if (data['status'] == 200 ) return 200
        else if (data['error']) return -1
        else {
          return (Math.ceil((data['loaded'] / data['total'])*100))
        }
      }),
      catchError((err)=> {
        console.error(err);
        return of(-1)
      })
  );
  }

downloadFile(fileId: String, originalname: String): any {
  return this.http.post(this.endpointURL + 'filedownload', { fileId: fileId, originalname: originalname }, { responseType: 'blob' })
}

}
