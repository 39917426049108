import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PostResponse } from '../interfaces/postResponse'
import { Sample } from '../interfaces/sample';
import { SampleQuery } from '../interfaces/sampleQuery';
import { UploadList, UploadListItem } from '../interfaces/uploadList';
import { Observable, of } from 'rxjs'
import { catchError, finalize, map, sample } from 'rxjs/operators';
import { MatSnackBar } from "@angular/material/snack-bar";

export interface SampleResponse {
  body: any,
  status: any,
  samples: any,
  error: any
}

@Injectable({
  providedIn: 'root'
})
export class SampleService {

  private readonly endpointURL = '/api/sample';

  constructor(private http: HttpClient, private snackbar: MatSnackBar) { }

  updateSamples(samples: [Sample]): Observable<any> { // <Boolean> does not work for some reason
    console.log(`${this.endpointURL}/update`, samples )
    return this.http.post<PostResponse>(`${this.endpointURL}/update`, samples).pipe(
      map( res => {
        console.log('res', res)
        return of(true)
      }),
      catchError((error)=>{
        this.snackbar.open('Error: Could not update sample information ',null,{duration:2000})
        console.error('error in update Samples: ', error);
        return of(false)
      })
    )
  }

  deleteSample(sampleQuery: SampleQuery): Promise<PostResponse> {
    return this.http.post<PostResponse>(`${this.endpointURL}/remove`, sampleQuery).toPromise()
  }

  getSamples(
    sampleQuery: SampleQuery = {},
    filter: string = '',
    sortOrder: string = 'asc',
    pageNumber: number = 1,
    pageSize: number = 1
  ): Observable<Sample[]> {
    return this.http.get<SampleResponse>(`${this.endpointURL}`, {
      params: new HttpParams()
        .set('sampleQuery', <string>JSON.stringify(sampleQuery))
        .set('filter', <string>filter)
        .set('sortOrder', <string>sortOrder)
        .set('pageNumber', <number>pageNumber)
        .set('pageSize', <number>pageSize)
    }).pipe(
      map(res => {
        return res.samples
      }),
      catchError((error)=>{
        this.snackbar.open('Error: Could not get samples from server',null,{duration:2000})
        console.error(error);
        return of([])
      })
    )
  }

  upload(uploadListItem: UploadListItem): Observable<Number> {
    const uploadForm = new FormData()
      uploadForm.append('originalName', <string>uploadListItem.originalname)
      uploadForm.append('sampleId', <string>uploadListItem.sampleId)
      uploadForm.append('checksum', <string>uploadListItem.checksum)
      uploadForm.append('fileId', <string>uploadListItem.fileId)
      uploadForm.append(<string>uploadListItem.fileId, <File>uploadListItem.file)
      return this.http.post<PostResponse>(`${this.endpointURL}/upload`, uploadForm, {
        reportProgress: true,
        observe: 'events'
      }).pipe(
        map((data) => {
          if (data['status'] == 200 ) return 200
          else if (data['error']) return -1
          else {
            return (Math.ceil((data['loaded'] / data['total'])*100))
          }
        }),
        catchError((err)=> {
          console.error(err);
          return of(-1)
        })
    );
    }

  downloadFile(fileId: String, originalname: String): any {
    return this.http.post(`${this.endpointURL}/filedownload`, { fileId: fileId, originalname: originalname }, { responseType: 'blob' })
  }

} 
