import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { SampleSetService } from '../services/sample-set.service';
import { SampleService } from '../services/sample.service';
import { MatSnackBar } from '@angular/material/snack-bar';


import * as XLSX from 'xlsx';
import { FormControl, FormGroup } from '@angular/forms';
import { SampleSet } from '../interfaces/sampleSet';
import { Sample } from '../interfaces/sample';

@Component({
  selector: 'app-csv-table',
  templateUrl: './csv-table.component.html',
  styleUrls: ['./csv-table.component.sass']
})
export class CsvTableComponent implements OnInit {

  private arrayBuffer: ArrayBuffer
  private sampleToBeUpdated: Sample
  public cols: any[]
  public file: File
  public fileName: String;
  public extractedJson: any[] = []
  public oneSampleId = false
  public csvIngestForm: FormGroup
  public availableSampleSets: SampleSet[]
  public availableSamples: Sample[]


  constructor(
    private sampleSetService: SampleSetService,
    private snackBar: MatSnackBar,
    private sampleService: SampleService) {
    this.csvIngestForm = new FormGroup({
      'selectedSample': new FormControl(''),
      'selectedSampleSet': new FormControl(''),
      'identifierColumn': new FormControl(''),
      'fileIdColumn': new FormControl(''),
      'measurmentColumn': new FormControl(''),
      'measuredValueColumn': new FormControl(''),
      'unitColumn': new FormControl('')
    })
  }

  ngOnInit() {

    this.sampleSetService.getSampleSet({},'','asc',1,null).subscribe(
      data => {
        this.availableSampleSets = data
        console.log(this.availableSampleSets)
      }
    )

    this.sampleService.getSamples({},'','asc',1,null).subscribe(
      data => {
        this.availableSamples = data
        console.log(this.availableSamples)
      }
    )

    }

  public openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    })
  }

  incomingfile(event: Event) {
    const element = event.target as HTMLInputElement
    let fileList: FileList | null = element.files
    if (fileList) {
      this.file = fileList[0];
      this.fileName = this.file.name
      this.Upload()
    }
  }

  Upload() {
    // extract JSON from file
    let fileReader = new FileReader()
    fileReader.onload = (event) => {
      this.arrayBuffer = <ArrayBuffer>fileReader.result
      var data = new Uint8Array(this.arrayBuffer)
      var arr = new Array()
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i])
      var bstr = arr.join("")
      var workbook = XLSX.read(bstr, { type: "binary" })
      var first_sheet_name = workbook.SheetNames[0]
      var worksheet = workbook.Sheets[first_sheet_name]
      this.extractedJson = XLSX.utils.sheet_to_json(worksheet, { raw: true })

      const extracted_cols: string[] = Object.keys(this.extractedJson[0])
      let table_cols:  Object[] = []

      extracted_cols.forEach(col => {
        table_cols.push({
          field: col, header: col
        })
      })

      this.cols = table_cols

      this.openSnackBar('Success: File read.')
    }
    fileReader.readAsArrayBuffer(this.file)

  }

  public digest(): void {

    this.sampleService.getSamples({_id: this.csvIngestForm.get('selectedSample').value})
    .subscribe( data => {

      this.sampleToBeUpdated = data[0]

      this.extractedJson.forEach( element => {

        this.sampleToBeUpdated.measurements.push({
          measurement: element[this.csvIngestForm.value.measurmentColumn],
          measurement_file_id: element[this.csvIngestForm.value.fileIdColum],
          measurement_identifier: element[this.csvIngestForm.value.identifierColumn]  ,
          measurement_value: element[this.csvIngestForm.value.measuredValueColumn],
          measurement_value_unit: element[this.csvIngestForm.value.unitColumn],
        })
      })

      this.sampleService.updateSamples([<Sample>this.sampleToBeUpdated]).subscribe(() => {
        this.openSnackBar('Sucessfully added measurements to sample.')
        setTimeout(()=>{window.location.reload()},3000)
      })

      })



  }


  // saveAsExcelFile(buffer: any, fileName: string): void {
  //   import("file-saver").then(FileSaver => {
  //     let EXCEL_TYPE =
  //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  //     let EXCEL_EXTENSION = ".xlsx"
  //     const data: Blob = new Blob([buffer], {
  //       type: EXCEL_TYPE
  //     })
  //     saveAs(
  //       data,
  //       fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
  //     )
  //   })
  // }
}
