import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http'
import { MatTableDataSource } from '@angular/material/table';

// services
import { SampleService } from '../services/sample.service'
import { IdGeneratorService } from '../services/id-generator.service';

// interfaces
import { _File } from '../interfaces/_file'
import { Material } from '../interfaces/material'
import { Etching } from '../interfaces/etching'
import { UploadList } from '../interfaces/uploadList'

// third party
import { saveAs } from 'file-saver';
import { BehaviorSubject, of } from 'rxjs';
import { ChecksumService } from '../services/checksum.service';
import { SampleFile } from '../interfaces/sampleFile';
import { map } from 'rxjs/internal/operators/map';
import { MeasurementEquipmentService } from '../services/measurementEquipment.service';


@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.sass']
})
export class SampleComponent implements OnInit {

  // sample properties handled outside the form
  public _id: String;
  public ascisafe_id: String;
  public filesDataSource: [_File] = void {};
  public originalname: String; //todo better solution!

  // available files for blob download
  public availableFiles: [SampleFile];
  public upload: any
  public uploadList: UploadList



  // MatTableDataSource for measurement
  public measurementDataSource: MatTableDataSource<any>;
  public processParameterDataSource: MatTableDataSource<any>;


  public sampleForm: FormGroup;


  public noMeasurments = true
  public noProcessparameters = true
  public noFiles = true

  // expert mode
  public expertMode = false
  public sampleFormAsText: string

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private sampleService: SampleService,
    private idGeneratorService: IdGeneratorService,
    private checksumService: ChecksumService,
    private measurementEquipmentService: MeasurementEquipmentService,
    public dialog: MatDialog) {
    this.uploadList = []

    // todo: form validation
    this.sampleForm = new FormGroup({
      'description': new FormControl('', Validators.required),
      'creationTimestamp': new FormControl('', Validators.required),
      'materials': new FormControl([], Validators.nullValidator), //todo add validator
      'etching': new FormControl([], Validators.nullValidator), //todo add validator
      'measurements': new FormControl([], Validators.nullValidator),
      'status': new FormControl('', Validators.nullValidator),
      'process_parameters': new FormControl([], Validators.nullValidator)
    });
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      if (params['_id']) {
        this.sampleService.getSamples({ _id: params['_id'] })
        .pipe(
          map((samples) => {
            
            // check if sample exists
            if(samples.length == 0) this.router.navigate(['not-found'])

            // get first sample
            if (samples.length > 1) this.openSnackBar('Error: Multiple samples with the same unique identifier!')
            const sample = samples[0]


            // populate form
            this.populateForm(sample)

            // sample _id and associated files are not accounted for in the form!
            if (sample.files && sample.files.length >= 1) {
              this.availableFiles = sample.files
              this.noFiles = false
            }
            this._id = sample._id
            this.ascisafe_id = this._id.replace(/:/g, '_')

            // populate tables
            if (this.sampleForm.value.measurements && !(this.sampleForm.value.measurements.length < 1)) this.noMeasurments = false
            if (this.sampleForm.value.process_parameters && !(this.sampleForm.value.process_parameters.length < 1)) this.noProcessparameters = false
            this.measurementDataSource = new MatTableDataSource(this.sampleForm.value.measurements)
            this.processParameterDataSource = new MatTableDataSource(this.sampleForm.value.process_parameters);

            // expert mode
            this.sampleFormAsText = JSON.stringify(this.sampleForm.value, null, 2)
          }),
          map( () => {
            // this.measurementEquipmentService.getMeasurementEquip()
          })
        )
        .subscribe()
      }
      else {
        this.router.navigate(['not-found'])
        this.openSnackBar('Error: Sample does not exist.')
      }
    })
  }


  private populateForm(sample) {
    console.log('get this in populate Form ', sample)
    if (!sample.creationTimestamp) this.sampleForm.controls['creationTimestamp'].setValue(this.getCurrentTimeStamp());
    if (sample.creationTimestamp) this.sampleForm.controls['creationTimestamp'].setValue(sample.creationTimestamp);
    if (!sample.materials) this.sampleForm.controls['materials'].setValue([
      {
        code_DIN_EN_10027: '1.4404',
        shortname__DIN_EN_10027: 'Edelstahl',
        properties: 'nichtrostend austenitischer Stahl'
      }]);
    if (sample.etching) this.sampleForm.controls['etching'].setValue(sample.etching)
    if (sample.status) this.sampleForm.controls['status'].setValue(sample.status);
    if (sample.materials) this.sampleForm.controls['materials'].setValue(sample.materials);
    if (sample.description) this.sampleForm.controls['description'].setValue(sample.description) 
    else this.sampleForm.controls['description'].setValue(null)
    if (sample.measurements) this.sampleForm.controls['measurements'].setValue(sample.measurements)
    else this.sampleForm.controls['measurements'].setValue([])
    if (sample.process_parameters) this.sampleForm.controls['process_parameters'].setValue(sample.process_parameters)
    else this.sampleForm.controls['process_parameters'].setValue([])
  }


  // UI related vars

  // upload
  public uploadList$ = new BehaviorSubject<{
    fileId: String,
            sampleId: String,
            checksum: String,
            originalname: String
            file: File,
            uploadProgress?: number
  }[]>(null)

  // table
  public process_parameterColumns: String[] = ['process_parameter_identifier', 'process_parameter_file_id', 'process_parameter',  'process_parameter_value', 'process_parameter_value_unit']
  public measurementColumns: String[] = ['measurement_identifier', 'measurement_file_id', 'measurement', 'measurement_value', 'measurement_value_unit']
  public displayedColumns: String[] = ['originalname', 'fileId', 'download'];
  public fileUploadColumns: String[] = ['originalname', 'checksum', 'status']
  // chips, see angular material module
  public visible = true;
  public selectable = true;
  public removable_etching = true;
  public removable_material = true;
  public addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // add chip 
  public add_material(event: MatChipInputEvent): void {
    let value = event.value;

    // add material
    if ((value || '').trim()) {

      // todo load available materials for validation and info completion

      // todo Validation

      this.sampleForm.value.materials.push({ code_DIN_EN_10027: value.trim() });
    }

    // reset input value
    event.chipInput!.clear();
  }

  public toggleExpertMode() {
    this.expertMode = !this.expertMode
  }

  public updateSampleFormFromText(event) {
    this.populateForm(JSON.parse(this.sampleFormAsText))
    this.measurementDataSource.data = this.sampleForm.value.measurements
    this.processParameterDataSource.data = this.sampleForm.value.process_parameter
  }

  public add_postProcessing(event: MatChipInputEvent): void {
    let value = event.value;

    if ((value || '').trim()) {

      // todo load available etchings
      // todo Validation
      this.sampleForm.value.etching.push({ friendly_name: value.trim() });
    }

    // reset input value
    event.chipInput!.clear();
  }

  public addNewMeasurement(): void {
    this.noMeasurments = false
    this.sampleForm.value.measurements.push({
      measurement: '',
      measurement_identifier: '',
      measurement_value: 0,
      measurement_value_unit: '',
    })
    this.measurementDataSource.data = this.sampleForm.value.measurements
  }

  public addNewProcessParameter(): void {
    this.noProcessparameters = false
    this.sampleForm.value.process_parameters.push({
      process_parameter: '',
      process_parameter_value: 0,
      process_parameter_value_unit: '',
    })
    this.processParameterDataSource.data = this.sampleForm.value.process_parameters
  }

  private getCurrentTimeStamp(): String {
    return new Date().toISOString()
  }

  public remove_material(material: Material): void {
    let index = this.sampleForm.value.materials.indexOf(material);

    if (index >= 0) {
      this.sampleForm.value.materials.splice(index, 1);
    }
  }

  public remove_etching(etching: Etching): void {
    let index = this.sampleForm.value.etching.indexOf(etching);

    if (index >= 0) {
      this.sampleForm.value.etching.splice(index, 1);
    }
  }


  public openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  public submit = function (): void {
    if(this.sampleForm.valid){
    const sampleSetData = {
      status: this.sampleForm.value.status,
      _id: this._id,
      description: this.sampleForm.value.description,
      materials: this.sampleForm.value.materials,
      etching: this.etching,
      process_parameters: this.sampleForm.value.process_parameters,
      measurements: this.sampleForm.value.measurements,
      files: this.files,
      creationTimestamp: this.sampleForm.value.creationTimestamp,
      lastEditedTimestamp: this.getCurrentTimeStamp(),
    }
    this.sampleService.updateSamples([sampleSetData]).subscribe(
      data => {
        this.openSnackBar('Succes: Saved sample set to database.')
      },
    )} 
  }


  // input (change) -->
  // #1 prepare file array
  // #1a for each file create new file id
  // #1b for each file create checksum
  // #2 upload file array 
  public fileListLength
  public currentFile

  public async setFile(fileList: FileList): Promise<any> {
    try {
    const uploadList = await this.pushFilesToUploadList(fileList)
    let okCounter = 0
    uploadList.forEach(element => {
      const originalname = element.originalname
      this.sampleService.upload(element).subscribe((uploadProgress: number) => {
        if (uploadProgress == null) uploadProgress = 0 
        else if (uploadProgress == 200) {
          uploadProgress = 100; 
          okCounter = okCounter + 1; 
          if(okCounter == 2*(uploadList.length)) {
            this.openSnackBar('Upload of all files successfull.')
            setTimeout(()=>{window.location.reload()},3000)
          }
        }
        else if (uploadProgress == -1 ) {uploadProgress = 0 ; this.openSnackBar('Error uploading file')}
        else { try {
          let valueOfUploadList$ = this.uploadList$.getValue()
        valueOfUploadList$.filter((element) => {return element.originalname == originalname})[0].uploadProgress = uploadProgress
        this.uploadList$.next(valueOfUploadList$) 
        
      } catch(err) { this.openSnackBar('Error while calculating upload progess'); uploadProgress = 0}}
      })
    });
  
  } catch (err) {
    console.error(err)
  }

  }

  private async pushFilesToUploadList(fileList: FileList): Promise<any> {
    this.uploadList$.next([])
    this.openSnackBar('Please wait while files are prepared for the upload.')
    for (const file of Array.from(fileList)) {
      const newFileId = await this.idGeneratorService.getNewIdPromise() //TODO get rid of this and remove this service function as well, all should use getNewId
      const checksum = await this.checksumService.returnChecksum(file)
      this.uploadList$.next([...this.uploadList$.getValue(), {
        'fileId': newFileId,
        'sampleId': this._id,
        'checksum': checksum,
        'originalname': file.name,
        'file': file
      }])

    }
    console.log('create Promise with ', this.uploadList$.getValue())
    return Promise.resolve(this.uploadList$.getValue())
  }

  public delete(sampleId: String) {
    // this.sampleService.deleteSample({ _id: sampleId }).then(data => {
    //   this.openSnackBar("Success: Deleted sample " + sampleId + ".")
    // }, error => {
    //   this.openSnackBar("Error: Could not delete sample.")
    //   console.error(error)
    // })
  }

  public download = function (fileId: String, originalname: String): void {

    let thefile = {};
    this.openSnackBar('Loading: Please wait until the file is downloaded...')
    this.sampleService.downloadFile(fileId, originalname)
      .subscribe((data: HttpResponse<any>) => {
        this.downloadFile(data, originalname);
      }
        , error => { console.error(error), this.openSnackBar("Error: Could not download file.") })
  }

  public downloadFile(data: any, filename?: String) {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
    if (filename) saveAs(blob, filename); else saveAs(blob, null)

  }


}

