import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';


@NgModule({
  // do not add declarations! see app.module.ts

imports: [
    TableModule,
    PanelModule,
    ButtonModule
],  
exports: [
    TableModule,
    PanelModule,
    ButtonModule
],
providers: [
]
})
export class PrimengModule {}
