import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, of } from "rxjs";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { catchError, finalize } from "rxjs/operators";
import { SampleSet } from "../interfaces/sampleSet";
import { SampleSetQuery } from "../interfaces/sampleSetQuery";
import { SampleSetService } from "../services/sample-set.service";


export class SampleSetDataSource implements DataSource<SampleSet> {


    private sampleSetSubject = new BehaviorSubject<SampleSet[]>([]);
    private sampleSetAmountSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public amount$ = this.sampleSetAmountSubject.asObservable();

    constructor(private sampleSetService: SampleSetService, private snackBar: MatSnackBar) {}

    connect(collectionViewer: CollectionViewer): Observable<SampleSet[]> {
        return this.sampleSetSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.sampleSetSubject.complete();
        this.loadingSubject.complete();
        this.sampleSetAmountSubject.complete();
    }

    loadSamples(sampleSetQuery: SampleSetQuery,
        filter = '',
        sortOrder = 'asc',
        pageNumber = 1,
        pageSize= 10
        ) {

        this.loadingSubject.next(true);
          this.sampleSetService.getSampleSet(sampleSetQuery, filter, 
            sortOrder, pageNumber, pageSize).pipe(
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(sampleSets => {
            this.sampleSetSubject.next(sampleSets)
        });

    }    
    loadSamplesInitially(sampleSetQuery: SampleSetQuery,
        filter = '',
        sortOrder = 'asc',
        pageNumber = 1,
        pageSize= 10
        ) {

        this.loadingSubject.next(true);
        this.sampleSetService.getSampleSet(sampleSetQuery, filter, 
            sortOrder, pageNumber, pageSize).pipe(
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(sampleSets => {
            console.log('sampleSets ', sampleSets )
            this.sampleSetSubject.next(sampleSets)
        });

        this.sampleSetService.getSampleSet(sampleSetQuery,filter,null, 1, null).subscribe(sampleSets => {
            this.sampleSetAmountSubject.next(sampleSets.length)
        })
    }    
}

