<mat-card>
  <mat-card-header>
    <mat-card-title>Sample Set</mat-card-title>
    <mat-card-subtitle>id: {{_id}}</mat-card-subtitle>
    <mat-card-subtitle>created: {{sampleSetForm.value.creationTimestamp}}</mat-card-subtitle>
  </mat-card-header>
  <mat-list>
    <mat-list-item>
      <form [formGroup]="sampleSetForm">
        <mat-form-field appearance="fill">
          <mat-label>description</mat-label>
          <input matInput formControlName="description">
        </mat-form-field>
        <br>
        <mat-form-field class="example-chip-list">
          <mat-chip-list #chipList aria-label="material selection">
            <mat-chip *ngFor="let material of sampleSetForm.value.materials" [selectable]="selectable"
              [removable]="removable" (removed)="remove(material)">
              {{material.code_DIN_EN_10027}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="materials" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
        </mat-form-field>
      </form>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <form [formGroup]="sampleSetForm">
        <p>associated samples</p>
        <br>
       
      <div style="position: relative;">
        <div style="display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.1);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;" *ngIf="sampleDataSource.loading$ | async">
            <mat-progress-spinner color="accent" mode="indeterminate" diameter="50">
            </mat-progress-spinner>
        </div>
        <mat-table mat-table [dataSource]="sampleDataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="shortSampleId">
                <th mat-header-cell *matHeaderCellDef>short id</th>
                <td mat-cell *matCellDef="let sample">{{sample._id.substring(sample._id.length-9)}}</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>description</th>
                <td  mat-cell *matCellDef="let sample">{{sample.description}}</td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>status</th>
              <td  mat-cell *matCellDef="let sample">{{sample.status}}</td>
          </ng-container>

            <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let sample">
                    <button mat-button color="accent" (click)="editSample(sample._id)">Edit sample</button>
                    <button mat-button color="accent" (click)="removeSampleFromSet(sample._id)">Remove sample from set</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="sampleColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: sampleColumns;"></tr>

        </mat-table>


      </div>

      
      
      
      
        <!-- <mat-accordion>
          <mat-expansion-panel hideToggle *ngFor="let sample of sampleSetForm.value.associatedSamples">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{sample.description}} <br>  {{sample._id}}
              </mat-panel-title>
                                         <mat-panel-description>
                {{sample.description}}
              </mat-panel-description>
            <p>{{sample.measurements}}</p>
          </mat-expansion-panel-header>
            <button mat-button color="accent" (click)="editAssociatedSample(sample._id)">Edit sample</button>
            <button mat-button color="accent" (click)="removeAssociatedSample(sample)">Remove sample from set</button>
          </mat-expansion-panel>
        </mat-accordion> -->
        <br>
        <form [formGroup]="newAssociatedSampleForm">
          <table class="example-full-width" cellspacing="0">
            <tr></tr>
            <td>
              <mat-form-field>
                <mat-label>sample id</mat-label>
                <input matInput formControlName="_id">
              </mat-form-field>
              <td> <button mat-button color="primary" >Scan</button>
            </td>
            <td> <button mat-button color="accent" [disabled]="newAssociatedSampleForm.status != 'VALID'"
                (click)="addNewAssociatedSample(newAssociatedSampleForm.value._id)">Add</button>
            </td>
          </table>
        </form>
      </form>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <section>
        <div class="example-button-row">
          <button mat-raised-button color="accent" [disabled]="sampleSetForm.status != 'VALID' || _id == undefined" (click)="submit()">Save sample set</button>
          <button mat-raised-button color="accent">Delete sample set</button>
        </div>
      </section>
    </mat-list-item>
  </mat-list>
  <mat-divider></mat-divider>

  <div *ngIf="!noFiles">
    <table mat-table [dataSource]="availableFiles" class="mat-elevation-z8">

      <ng-container matColumnDef="originalname">
        <th class="tab" mat-header-cell *matHeaderCellDef> originalname </th>
        <td class="tab" mat-cell *matCellDef="let element"> {{element.originalname}} </td>
      </ng-container>

      <ng-container matColumnDef="fileId">
        <th class="fileId" mat-header-cell *matHeaderCellDef> fileId </th>
        <td class="fileId" mat-cell *matCellDef="let element"> {{element.fileId}} </td>
      </ng-container>

      <ng-container matColumnDef="download">
        <th class="tab" mat-header-cell *matHeaderCellDef></th>
        <td class="tab" mat-cell *matCellDef="let element">
          <button mat-button color="accent" (click)="download(element.fileId, element.originalname)">Download
            file</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <br>

  <input (change)="setFile($event.target.files)" type="file" name="sampleFile" id="file" multiple>
  <div *ngIf="(uploadList$ | async) as filesToBeUploaded">
    <table mat-table [dataSource]="uploadList$ | async" class="mat-elevation-z8">
      <ng-container matColumnDef="originalname">
        <th class="tab" mat-header-cell *matHeaderCellDef> originalname </th>
        <td class="tab" mat-cell *matCellDef="let element"> {{element.originalname}} </td>
      </ng-container>
      <ng-container matColumnDef="checksum">
        <th class="tab" mat-header-cell *matHeaderCellDef> local checksum </th>
        <td class="tab" mat-cell *matCellDef="let element"> {{element.checksum}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th class="fileId" mat-header-cell *matHeaderCellDef> upload status </th>
        <td class="fileId" mat-cell *matCellDef="let element"> {{element.uploadProgress}}% </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="fileUploadColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: fileUploadColumns;"></tr>
    </table>

  </div>
  <ng-template #noFilesUploading>no files</ng-template>
  <!-- <label for="input-file-id" class="md-button md-raised md-primary">Choose Files</label>   -->

  <br>
  <mat-divider></mat-divider>
</mat-card>