import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CsvDataService } from '../services/csv-data.service';
import { IdGeneratorService } from '../services/id-generator.service'

@Component({
  selector: 'app-id-generator',
  templateUrl: './id-generator.component.html',
  styleUrls: ['./id-generator.component.sass']
})
export class IdGeneratorComponent implements OnInit {

  public generatedIds: MatTableDataSource<any> // TODO make it enforce an array of string
  public idGeneratorForm: FormGroup
  public expertMode = false
  public idColumns: string[] = ['index','id']

  constructor(
    private idGeneratorService: IdGeneratorService,
    private snackBar: MatSnackBar,
    private csvService :CsvDataService
  ) {
    this.idGeneratorForm = new FormGroup({
      'idAmount': new FormControl(10, Validators.required),
      'adressRangeKnown': new FormControl(false, Validators.required),
      'addressRange': new FormControl('')
    })
    this.generatedIds = new MatTableDataSource([])
  
  }

  ngOnInit(): void {

  }

  public openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  public async createIds(): Promise<void> {
    this.idGeneratorService.getNewId(this.idGeneratorForm.value.idAmount).subscribe(ids => {

      if (ids.length === 0) { this.openSnackBar('Error: Could not receive new ids from server.') } 
      else { this.generatedIds.data= ids;this.generatedIds._updateChangeSubscription()}
    })
  }
  public toggleExpertMode() {
    this.expertMode = !this.expertMode
  }

  public downloadAsCsv() {
    let rows: object[] = []
    if(this.generatedIds.data.length > 0) {
      this.generatedIds.data.forEach((_id,_index) => {
       rows.push({index: _index, id: _id})
      })
      this.csvService.exportToCsv('generatedIds',rows)
    }
  }


}
