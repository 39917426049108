import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ChecksumService {
    async returnChecksum(file: File): Promise<String> {
        let checksum = await new Promise<String>((resolve) => {
            const reader = new FileReader()
            var arrayBuffer: any = null // todo: solve why this cant be of type ArrayBuffer
            reader.onload = async function (ev) {
                // 
                crypto.subtle.digest('SHA-256', <ArrayBuffer>ev.target.result).then(hashBuffer => {
                    // Convert hex to hash, see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
                    const hashArray = Array.from(new Uint8Array(hashBuffer));
                    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
                    resolve(hashHex)
                }).catch(ex => console.error(ex));
            };
            reader.onerror = function (err) {
                console.error("Failed to read file", err);
            }
            reader.readAsArrayBuffer(file)
        })
        return checksum
    }
}